<nav
  class="navbar navbar-expand-lg navbar-light"
  style="background-color: #e4e3e6;"
>
  <img
    style="width: 300px; height: 80px;"
    src="https://autoplan.pe//img/logo-final.png"
  />
  <a class="navbar-brand" href="#">Solicitud de Remate Online</a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav ml-auto mr-5">
      <a class="nav-item nav-link" href="https://www.autoplan.pe/">Inicio</a>
      <a class="nav-item nav-link" href="https://www.autoplan.pe/site/about"
        >Nosotros</a
      >
      <a class="nav-item nav-link" href="https://www.autoplan.pe/site/quotation"
        >Cotiza</a
      >
    </div>
  </div>
</nav>

<!-- <br style="background-color:rgba(230, 141, 25, 0.6)" />
<br style="background-color:rgba(230, 141, 25, 0.6)"   />
<br style="background-color:rgba(230, 141, 25, 0.6)" />
<br style="background-color:rgba(230, 141, 25, 0.6)" /> -->

<div
  class="container-fluid"
  style="background-color: rgba(230, 141, 25, 0.6); height: 1230px;"
>
  <div class="row">
    <div class="col-xl-2"></div>
    <div class="col-xl-8">
      <mat-horizontal-stepper
        linear
        #stepper
        style="
          font-family: 'Montserrat', sans-serif;
          background-color: rgba(230, 141, 25, 0) !important;
        "
      >
        <mat-step
          [completed]="isStep1"
          style="background-color: #5a5555;"
        >
        <ng-template matStepLabel>{{this.strpaso1}}</ng-template>
          <!-- Paso 1 -->

          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <form
                (ngSubmit)="submit(registerForm, 1)"
                [formGroup]="registerForm"
                class="card mt-2"
              >
                <div
                  class="card-body"
                  style="background-color: rgb(255, 255, 255) !important;"
                >
                  <strong
                    ><P
                      style="
                        text-align: center;
                        font-size: 15px;
                        color: black !important;
                      "
                      >INGRESO DE DATOS PERSONALES</P
                    ></strong
                  >

                  <br />
                <!--     <label for="lblDocumentoIdentidad"
                      >Documento de identidad</label> -->

                    <mat-form-field class="my-form-field example-full-width">  
                      <mat-label>Número de Documento</mat-label>
                      <input matInput 
                            type="text"
                            autocomplete="off"
                            onkeypress="return SoloNumeros(event,this,11);"
                            formControlName="documento"
                            id="documento"
                            aria-describedby="emailHelp"
                            placeholder="Ingrese número de documento"
                            
                      />
                      <mat-icon matSuffix>person</mat-icon>
                      <mat-error *ngIf="documento.invalid &&
                      (documento.dirty || documento.touched)">
                        Ingrese su número de DNI válido
                      </mat-error>
                    </mat-form-field>   


                   <br/>

              

                   <!--  <div
                      *ngIf="
                        documento.invalid &&
                        (documento.dirty || documento.touched)
                      "
                      class="text-danger"
                    >
                      <div
                        class="ml-3"
                        *ngIf="
                          documento.errors.required ||
                          documento.errors.minlength
                        "
                      >
                        Ingrese su número de DNI válido
                      </div>
                    </div> -->

                    <mat-form-field my-form-field class="my-form-field example-full-width">  
                      <mat-label>Correo</mat-label>
                      <input matInput 
                            type="text"
                            autocomplete="off"
                            id="correo"
                            formControlName="correo"
                            aria-describedby="emailHelp"
                            placeholder="Ingrese correo electrónico"
                      />
                      <mat-icon matSuffix>local_post_office</mat-icon>
                      <mat-error *ngIf="correo.invalid && (correo.dirty || correo.touched)">
                        Ingrese un correo electrónico válido
                      </mat-error>
                    </mat-form-field>     

                    <br/>

           <!--          <label for="lblCorreo">Correo</label>
                    <input
                      [ngClass]="{
                        ' border-danger':
                          (correo.touched || correo.dirty) && !correo.valid
                      }"
                      type="text"
                      autocomplete="off"
                      class="form-control"
                      id="correo"
                      formControlName="correo"
                      aria-describedby="emailHelp"
                      placeholder="Ingrese correo electrónico"
                    />
                    <div
                      *ngIf="correo.invalid && (correo.dirty || correo.touched)"
                      class="text-danger"
                    >
                      <div
                        class="ml-3"
                        *ngIf="
                          correo.errors.required ||
                          correo.errors.minlength ||
                          correo.errors.email
                        "
                      >
                        Ingrese un correo electrónico válido
                      </div>
                    </div> -->

                  <mat-form-field class="my-form-field example-full-width">  
                      <mat-label>Celular</mat-label>
                      <input
                        matInput
                        type="text"
                        autocomplete="off"
                        onkeypress="return SoloNumeros(event,this,9);"
                        id="celular"
                        formControlName="celular"
                        placeholder="Ingrese celular"
                      />
                      <mat-icon matSuffix>local_phone</mat-icon>
                      <mat-error *ngIf="celular.invalid && (celular.dirty || celular.touched)">
                        Ingrese un celular válido
                      </mat-error>
                  </mat-form-field>

                  <br/>
                  <br/>
                  <br/>

                 <!--  <div class="form-group">
                    <label for="lblCelular">Celular</label>
                    <input
                      [ngClass]="{
                        ' border-danger':
                          (celular.touched || celular.dirty) && !celular.valid
                      }"
                      type="text"
                      autocomplete="off"
                      onkeypress="return SoloNumeros(event,this,9);"
                      class="form-control"
                      id="celular"
                      formControlName="celular"
                      placeholder="Ingrese celular"
                    />
                    <div
                      *ngIf="
                        celular.invalid && (celular.dirty || celular.touched)
                      "
                      class="text-danger"
                    >
                      <div
                        class="ml-3"
                        *ngIf="
                          celular.errors.required || celular.errors.minlength
                        "
                      >
                        Ingrese un celular válido
                      </div>
                    </div>
                  </div> -->

                  <div id="gwd-reCAPTCHA_2">
                    <!-- <div class="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="capcha"
                        formControlName="capcha"
                      />
                      <label class="custom-control-label" for="capcha"
                        >No soy un robot</label
                      >6Lf79OgUAAAAAJwNmkdkYIHhe5wl64MnMRV1hBAo
                    </div> -->

<!--                     6Lf79OgUAAAAAJwNmkdkYIHhe5wl64MnMRV1hBAo
                      <re-captcha 
                        [siteKey]="reCapchaKey" remplazar
                        formControlName="recaptchaReactive"
                        id="recaptchaReactive"
                      ></re-captcha>   -->
                      

                      <re-captcha (resolved)="resolved($event)" 
                        formControlName="recaptchaReactive" 
                        [siteKey]="reCapchaKey">  

	                     </re-captcha>

                  
                  </div>


                  
                  <div style="text-align: center;">
                    <br />
                    <button
                      class="btn boton_personalizado"
                      [disabled]="registerForm.invalid"
                      (click)="ValidarStep2(stepper, '1', registerForm)"
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-sm-3"></div>
          </div>
        </mat-step>
        <mat-step  [completed]="isStep2">
          <!-- Paso 2 -->
          <ng-template matStepLabel>{{this.strpaso2}}</ng-template>

          <div *ngIf="apiPaso1">
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-6">
                <form
                  (ngSubmit)="submit(registerForm2, 2)"
                  [formGroup]="registerForm2"
                  class="card mt-2"
                >
                  <div class="card-body">
                    <strong
                      ><P
                        style="
                          text-align: center;
                          font-size: 15px;
                          color: black !important;
                        "
                        >INGRESA EL CÓDIGO DE VERIFICACIÓN</P
                      ></strong
                    >
                    <br />

                  
                       
                    <div class="form-group">

                     
                      <label
                        >Ingrese el código que te enviamos</label
                      >

                       <div class="container-token">
                          <mat-form-field class="token-field">  
                            <input
                              matInput
                              type="text"
                              autocomplete="off"
                              maxlength="1"
                              min="0"
                              max="9"
                              onkeypress="return SoloNumeros(event,this,9);"
                              (keyup)="pasarSiguiente($event);"
                              formControlName="token1"
                              id="token1"
                            />
                            <mat-error *ngIf="token1.invalid && (token1.dirty || token1.touched)">
                            </mat-error>  
                          </mat-form-field>
  
                          <mat-form-field class="token-field">  
                            <input
                              matInput
                              type="text"
                              maxlength="1"
                              min="0"
                              max="9"
                              autocomplete="off"
                              onkeypress="return SoloNumeros(event,this,9);"
                              (keyup)="pasarSiguiente($event);"
                              formControlName="token2"
                              id="token2"
                            />
                            <mat-error *ngIf="token2.invalid && (token2.dirty || token2.touched)">
                             
                            </mat-error>  
                          </mat-form-field>
                    
                          <mat-form-field class="token-field">  
                            <input
                              matInput
                              type="text"
                              maxlength="1"
                              min="0"
                              max="9"
                              autocomplete="off"
                              onkeypress="return SoloNumeros(event,this,9);"
                              (keyup)="pasarSiguiente($event);"
                              formControlName="token3"
                              id="token3"
                            />
                            <mat-error *ngIf="token3.invalid && (token3.dirty || token3.touched)">
                            </mat-error>  
                          </mat-form-field>
  
                          <mat-form-field class="token-field">  
                            <input
                              matInput
                              type="text"
                              maxlength="1"
                              min="0"
                              max="9"
                              autocomplete="off"
                              onkeypress="return SoloNumeros(event,this,9);"
                              (keyup)="pasarSiguiente($event);"
                              formControlName="token4"
                              id="token4"
                            />
                            <mat-error *ngIf="token4.invalid && (token4.dirty || token4.touched)">
                            </mat-error>  
                          </mat-form-field>
  
                          <mat-form-field class="token-field">  
                            <input
                              matInput
                              type="text"
                              maxlength="1"
                              min="0"
                              max="9"
                              autocomplete="off"
                              onkeypress="return SoloNumeros(event,this,9);"
                              (keyup)="pasarSiguiente($event);"
                              formControlName="token5"
                              id="token5"
                            />
                            <mat-error *ngIf="token5.invalid && (token5.dirty || token5.touched)">
                            </mat-error>  
                          </mat-form-field>
  
                          <mat-form-field class="token-field">  
                            <input
                              matInput
                              type="text"
                              maxlength="1"
                              min="0"
                              max="9"
                              autocomplete="off"
                              onkeypress="return SoloNumeros(event,this,9);"
                              (keyup)="pasarSiguiente($event);"
                              formControlName="token6"
                              id="token6"
                            />
                            <mat-error *ngIf="token6.invalid && (token6.dirty || token6.touched)">
                            </mat-error>  
                          </mat-form-field>

                      </div>
    

                      <!-- <input
                        type="text"
                        autocomplete="off"
                        onkeypress="return SoloNumeros(event,this,6);"
                        class="form-control"
                        formControlName="codigosms"
                        id="codigosms"
                        aria-describedby="emailHelp"
                        placeholder="Ingrese código SMS"
                      /> -->

                      <!-- <div
                        *ngIf="
                          codigosms.invalid &&
                          (codigosms.dirty || codigosms.touched)
                        "
                        class="text-danger"
                      >
                        <div
                          class="ml-3"
                          *ngIf="
                            codigosms.errors.required ||
                            codigosms.errors.minlength
                          "
                        >
                          Ingrese el código SMS
                        </div>
                      </div>  -->
                    </div>

                    <div style="text-align: center;">
                      <br />
                      <button
                        [disabled]="registerForm2.invalid"
                        (click)="ValidarStep2(stepper, '2', registerForm2)"
                        class="btn boton_personalizado"
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-sm-3"></div>
            </div>
          </div>

          <div *ngIf="!apiPaso1">
            <form
              (ngSubmit)="submit(registerForm3, 5)"
              [formGroup]="registerForm3"
              class="card mt-2"
            >
              <div class="card-body">
                <strong
                  ><P
                    style="
                      text-align: center;
                      font-size: 15px;
                      color: black !important;
                    "
                    >LO SENTIMOS,</P
                  ></strong
                >
                <br />
                <blockquote class="text-center">
                  <p>
                    {{ errorRespuesta.description }}
                  </p>
                </blockquote>

                <div style="text-align: center;">
                  <br />

                  <button
                    mat-button
                    matStepperPrevious
                    class="btn boton_personalizado"
                  >
                    ATRAS
                  </button>
                </div>
              </div>
            </form>
          </div>
        </mat-step>

        <mat-step completed="false">
          <!-- Paso 3 -->
          <ng-template matStepLabel>{{this.strpaso3}}</ng-template>

          <div *ngIf="apiPaso2">
            <form
              (ngSubmit)="submit(registerForm3, 3)"
              [formGroup]="registerForm3"
              class="card mt-2"
            >
              <div class="card-body">
                <strong
                  ><P
                    style="
                      text-align: center;
                      font-size: 15px;
                      color: black !important;
                    "
                    >INGRESAR DATOS DE SOLICITUD</P
                  ></strong
                >
                <br />
                <div class="row">
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="lbldniruc">DNI / RUC</label>
                      <input
                        type="number"
                        disabled="true"
                        class="form-control"
                        formControlName="dniruc"
                        id="dniruc"
                        aria-describedby="emailHelp"
                      />

                      <br />
                    </div>
                  </div>

                  <div class="col-sm-5">
                    <div class="form-group">
                      <label for="lblapellidos"
                        >Apellidos y Nombres / Razon Social
                      </label>
                      <input
                        type="text"
                        disabled="true"
                        class="form-control"
                        formControlName="apellidos"
                        id="apellidos"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="lblasociado">Número de Vacante</label>
                      <input
                      [(ngModel)]="vnumeroVancante"
                      
                        type="number"
                        disabled="true"
                        class="form-control"
                        formControlName="asociado"
                        id="asociado"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-3">

                      <mat-form-field>
                        <mat-label>Seleccione Programa</mat-label>
                        <mat-select
                        [(ngModel)]="selectPrograma"
                        id="programa"
                        formControlName="programa"
                        (selectionChange)="cargarGrupos($event.value)"
                        
                        >
                          <mat-option *ngFor="let item of programas" [value]="item.codigo">
                            {{ item.descripcion }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    
                   

                   
                  </div>

                  <div class="col-sm-3">

                    <mat-form-field>
                      <mat-label>Seleccione grupo</mat-label>
                      <mat-select
                      [(ngModel)]="selectGrupo"
                        id="grupo"
                        formControlName="grupo"
                        (selectionChange)="cargarContratos($event.value)"
                      >
                        <mat-option *ngFor="let item of grupos" [value]="item.codigo">
                          {{ item.descripcion }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="grupo.invalid && (grupo.dirty || grupo.touched)">
                        Seleccione un grupo
                      </mat-error>
                    </mat-form-field>

               
                  </div>
                  <div class="col-sm-3">

                    <mat-form-field>
                      <mat-label>Seleccione Contrato</mat-label>
                      <mat-select
                      [(ngModel)]="selectContrato"
                        (selectionChange)="cargarContratosDetalle($event.value)"
                        id="contrato"
                        formControlName="contrato"
                      >
                        <mat-option *ngFor="let item of contratos" [value]="item.codigo">
                          {{ item.numero }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="contrato.invalid &&
                      (contrato.dirty || contrato.touched)">
                        Seleccione un contrato
                      </mat-error>
                    </mat-form-field>

      
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Valor del certificado </label>
                      <input
                        [(ngModel)]="vvalorCertificado"
                        type="text"
                        disabled="true"
                        class="form-control"
                        formControlName="certificado"
                        id="certificado"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                </div>
                

                <div class="row">
                  <div class="col-sm-6">

                    
                    <div class="form-group">

                       
                      <label for="lblcertificado">Cuotas a rematar </label>
                      <div class="conteiner">
                        <div class="row">

                          


                          <div class="col-sm-3">

                           
                            <!-- <mat-form-field class="my-form-field example-full-width">  
                              <mat-label>Cuotas a rematar</mat-label>
                              <input matInput 
                                      [(ngModel)]="vvalorCuotaRemate"
                                      onkeypress="return SoloNumeros(event,this,2);"
                                      (keyup)="onKeydown($event.target.value)"
                                      formControlName="cuotaremate"
                                      id="cuotaremate"
                                      aria-describedby="emailHelp"                                      
                              />
                              <mat-icon matSuffix>person</mat-icon>
                              <mat-error *ngIf="cuotaremate.invalid &&
                              (cuotaremate.dirty || cuotaremate.touched)">
                                Ingrese las cuotas de remate
                              </mat-error>
                            </mat-form-field> -->


                            <input
                              [(ngModel)]="vvalorCuotaRemate"
                              type="text"
                              onkeypress="return SoloNumeros(event,this,2);"
                              (keyup)="onKeydown($event.target.value)"
                              class="form-control"
                              formControlName="cuotaremate"
                              id="cuotaremate"
                              aria-describedby="emailHelp"
                              
                            />
                          </div>
                          <div class="col-sm-9">
                            <input
                              [(ngModel)]="vmontoCuotaRematar"
                              type="text"
                              disabled="true"
                              class="form-control"
                              formControlName="montoremate"
                              id="montoremate"
                              aria-describedby="emailHelp"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="
                          cuotaremate.invalid &&
                          (cuotaremate.dirty || cuotaremate.touched)
                        "
                        class="text-danger"
                      >
                        <div
                          class="ml-3"
                          *ngIf="
                            (cuotaremate.errors.required ||
                              cuotaremate.errors.minlength) &&
                            !flagPlan21
                          "
                        >
                          Ingrese cuotas de remate hasta
                          {{ contratosDetalle.cuotasPendientes }}
                        </div>
                        <div
                          class="ml-3"
                          *ngIf="(flagPlan21 && cuotaremate.errors.required )|| cuotaremate.errors?.min"
                        >
                          Ingrese cuotas a rematar de 21 hasta
                          {{ contratosDetalle.cuotasPendientes }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="lblcuotaspagadas">Cuotas pagadas</label>
                          <input
                            [(ngModel)]="vCuotasPagadas"
                            type="number"
                            disabled="true"
                            class="form-control"
                            formControlName="numerocuota"
                            id="numerocuota"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="lblcuotaspagadas"
                            >Fecha de Asamblea</label
                          >
                          <input
                            [(ngModel)]="vmontoCuotasPagadas"
                            type="text"
                            disabled="true"
                            class="form-control"
                            formControlName="montocuota"
                            id="montocuota"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="lblcertificado">Aplicación del remate </label>
                      <div class="conteiner">
                        <div class="row">
                          <div class="col-sm-2"></div>
                          <div class="col-sm-4">

                            
                            <div class="custom-control custom-checkbox">

                           
                            <mat-checkbox
                            [(ngModel)]="chkCuotas"
                            id="aplicaremate"
                            formControlName="aplicaremate"
                            (change)="checkValue(1)"
                            >
                            Últimas cuotas
                            </mat-checkbox>

                              <!-- <input
                                [(ngModel)]="chkCuotas"
                                type="checkbox"
                                class="custom-control-input"
                                id="aplicaremate"
                                formControlName="aplicaremate"
                                (change)="checkValue(1)"
                                
                              />
                              <label
                                class="custom-control-label"
                                for="aplicaremate"
                                >Ultimas cuotas</label
                              > -->
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="custom-control custom-checkbox">

                              <mat-checkbox
                              [(ngModel)]="chkProrrateo"
                              id="prorrateo"
                              formControlName="prorrateo"
                              (change)="checkValue(2)"
                              >
                              Prorrateo
                              </mat-checkbox>

                              <!-- <input
                                [(ngModel)]="chkProrrateo"
                                type="checkbox"
                                class="custom-control-input"
                                id="prorrateo"
                                formControlName="prorrateo"
                                (change)="checkValue(2)"
                              />
                              <label
                                class="custom-control-label"
                                for="prorrateo"
                                >Prorrateo</label
                              > -->
                            </div>
                          </div>

                          <div class="col-sm-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="this.enviado && !this.isValidRemate">
                   <div class="col-sm-6">
                       <div class="form-group">
                            <div
                            class="text-danger"
                          >
                            Ingrese la aplicación del remate
                          </div>
                       </div>
                   </div>
                </div>

                

                <div class="custom-control custom-checkbox">

                 

                  <input
                    type="checkbox"
                    [(ngModel)]="chkAutorizo"
                    class="custom-control-input"
                    id="autoriza"
                    formControlName="autoriza"
                  />
                  <label class="custom-control-label" for="autoriza"
                    >
                    
                    Autorizo a AUTOPLAN EAFC S.A. para que me representen en la asamblea del
                     grupo N° {{selectGrupoDetalle}} el día {{vmontoCuotasPagadas  | date:'dd/MM/yyyy'}} la misma que se celebrará en la ciudad de Lima.
                    
                    
                </label>
                  
                </div>

                <div class="row" *ngIf="this.enviado && !this.chkAutorizo">
                  <div class="col-sm-6">
                      <div class="form-group">
                           <div
                           class="text-danger"
                         >
                           Seleccione la autorización
                         </div>
                      </div>
                  </div>
                </div>

                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    [(ngModel)]="chkDeclaro"
                    class="custom-control-input"
                    id="declaro"
                    formControlName="declaro"
                  />
                  <label class="custom-control-label" for="declaro">
                    <a [href]="rutaPDF" target="_blank" class="form-check-label"
                      >Declaro haber leido los términos y condiciones</a
                    >
                  </label>
                </div>

               <div class="row" *ngIf="this.enviado && !this.chkDeclaro">
                  <div class="col-sm-6">
                      <div class="form-group">
                           <div
                           class="text-danger"
                         >
                           Seleccione la declaración de términos y condiciones
                         </div>
                      </div>
                  </div>
                </div>

                <div style="text-align: center;">
                  <br />
                  <button
                    [disabled]="registerForm3.invalid"
                    (click)="ValidarStep(stepper, '3')"
                    class="btn boton_personalizado"
                  >
                    Continuar
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="!apiPaso2">
            <form
              (ngSubmit)="submit(registerForm3, 5)"
              [formGroup]="registerForm3"
              class="card mt-2"
            >
              <div class="card-body">
                <strong
                  ><P
                    style="
                      text-align: center;
                      font-size: 15px;
                      color: black !important;
                    "
                    >LO SENTIMOS,</P
                  ></strong
                >
                <br />
                <blockquote class="text-center">
                  <p>
                    {{ errorRespuesta.description }}
                  </p>
                </blockquote>

                <div style="text-align: center;">
                  <br />
                  <button
                    mat-button
                    matStepperPrevious
                    class="btn boton_personalizado"
                  >
                    ATRAS
                  </button>
                </div>
              </div>
            </form>
          </div>
        </mat-step>
        <!-- Paso 4 -->
        <mat-step  completed="false">
          <ng-template matStepLabel>{{this.strpaso4}}</ng-template>
          <form
            (ngSubmit)="submit(registerForm3, 4)"
            [formGroup]="registerForm3"
            class="card mt-2"
          >
            <div class="card-body">
              <h2>
                <strong
                  ><P
                    style="
                      text-align: center;
                      font-size: 15px;
                      color: black !important;
                    "
                    >{{ vrespuestaSolicitud1 }}</P
                  ></strong
                >
              </h2>
              <br />
              <blockquote class="text-center">
                <p>
                  {{ vrespuestaSolicitud2 }}
                </p>
              </blockquote>

              <div style="text-align: center;">
                <br />
                <button
                  onclick="javascript:location.reload();"
                  class="btn boton_personalizado"
                >
                  Nueva Solicitud
                </button>
              </div>
            </div>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <div class="col-xl-2"></div>
  </div>

  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>






 <!-- <ngx-spinner
  bdColor="rgba(0, 0, 0, 1)">
  template="<img src='https://media.giphy.com/media/o8igknyuKs6aY/giphy.gif' />" 
</ngx-spinner>  -->
</div>
