import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { inject } from '@angular/core/testing';
import { MatStepper } from '@angular/material/stepper';
import {
  IDatosPersonales,
  IError,
  IProgramas,
  IGrupos,
  IContratos,
  IContratosDetalle,
  IEnvioSolicitud,
} from './datos-personales';
import { PrincipalServiceService } from './principal-service.service';
import { ReturnStatement, IfStmt } from '@angular/compiler';
import { JsonPipe } from '@angular/common';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';
import { RouteReuseStrategy } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorStateMatcher } from '@angular/material/core';


// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const isSubmitted = form && form.submitted;
//     //return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//     return control.invalid && (control.dirty || control.touched);
//   }
// }

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css'],
})

export class PrincipalComponent implements OnInit {

  enviado = false;
  isValidRemate = false;
  chkProrrateo : boolean = false;
  chkCuotas : boolean = false;
  chkAutorizo : boolean = false;
  chkDeclaro : boolean = false;
  strpaso1 : string = "";
  strpaso2 : string = "";
  strpaso3 : string = "";
  strpaso4 : string = "";
  codigoSms : string = "";

  ngOnInit() {
    // this.reactiveForm = new FormGroup({
    //   recaptchaReactive: new FormControl(null, Validators.required)
    // });
    /** spinner starts on init */
    var ua = navigator.userAgent;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
    {
       console.log('device');
       this.strpaso1  = "";
       this.strpaso2 = "";
       this.strpaso3 = "";
       this.strpaso4 = "";
    }
    else {
       console.log('no device');
       this.strpaso1 = "Paso 1";
       this.strpaso2 = "Paso 2";
       this.strpaso3 = "Paso 3";
       this.strpaso4 = "Paso 4";
    }
       
    this.spinner.show();
 
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }
  public vcuota21 = 1;
  rutaPDF = environment.baseUrl + 'autoplanPDF.pdf';
  reCapchaKey = environment.reCapchaKeyV2

  constructor(
    private formBuilder: FormBuilder,
    private formBuilder2: FormBuilder,
    private formBuilder3: FormBuilder,
    private principalService: PrincipalServiceService,
    private spinner: NgxSpinnerService
  ) {}

  datosPersonales: IDatosPersonales;
  errorRespuesta: IError;
  programas: IProgramas[];
  grupos: IGrupos[];
  gruposfilter: IGrupos[];
  contratos: IContratos[];
  contratosDetalle: IContratosDetalle;
  envioSolicitud: IEnvioSolicitud[];
  errorRespuestaSolicitud: IError[];

  apiPaso1 = true;
  apiPaso2 = true;

  async cargarData(d: string, cr: string, c: string) {
    this.principalService.getValidaDatosPersonales(d, cr, c).subscribe(
      (response: any) => {
        console.log(response);
        // alert(response.status);
        this.datosPersonales = response.body;
        this.apiPaso1 = true;
        // alert( JSON.stringify(this.datosPersonales));
      },
      (error) => {
        this.apiPaso1 = false;
        // alert(JSON.stringify(error.error)) ;
        this.errorRespuesta = error.error;
        // alert(this.errorRespuesta.description) ;
      }
    );
  }

  pasarSiguiente(e : any) {
    var key = e.which;

    let itemSiguiente = parseInt(e.target.id.substring(e.target.id.length - 1, e.target.id.length)) + 1;
    //console.log("siguiente:" + itemSiguiente);
    //console.log("key",key);

    if (itemSiguiente <= 6 && itemSiguiente > 0) {
      //console.log("entro");
        if(key !==8){
            if((key >= 48 || key <= 57 ) && key !== 229){
                //console.log("entro2"); 
                //console.log("entro3"); 
                document.getElementById("token" + itemSiguiente).focus(); 
            }
        }
    }
  }

  async ValidaCodigoSeguridad(cs: number, ca: number, ni: number) {
    this.principalService.getValidaCodigoSeguridad(cs, ca, ni).subscribe(
      (response: any) => {
        console.log(response);
        // alert(response.status);
        // this.datosPersonales=response.body;
      this.apiPaso2 = true;
        // alert( JSON.stringify(this.datosPersonales));
      },
      (error) => {
        this.apiPaso2 = false;
        // alert(JSON.stringify(error.error)) ;
        this.errorRespuesta = error.error;
        //  alert(this.errorRespuesta.description) ;
      }
    );
  }
  flagPlan21: boolean;

  async cargarProgramas(na: number) {
    this.principalService.getProgramas(na).subscribe(
      (response: any) => {
        console.log(response);
        // alert(response.status);
        this.programas = response.body;
        //this.apiPaso2=true;
        //  alert( JSON.stringify(this.programas));
        this.selectPrograma = this.programas[0].codigo;

        this.cargarGrupos(this.selectPrograma);
      },
      (error) => {
        //this.apiPaso2=false;
        // alert(JSON.stringify(error.error)) ;
        this.errorRespuesta = error.error;
        //  alert(this.errorRespuesta.description) ;
      }
    );
  }

  async cargarGrupos(cp: string) {
    // alert(this.selectPrograma);

    // alert(this.datosPersonales.codigo);
    this.principalService
      .getGrupos(this.datosPersonales.codigo.toString(), cp)
      .subscribe(
        (response: any) => {
          console.log(response);
          // alert(response.status);
          this.grupos = response.body;
         

          //this.apiPaso2=true;
          // alert( JSON.stringify(this.datosPersonales));

          // get asociado() {
          //   return this.registerForm3.get('asociado');
          // }


          if (cp == 'AU0003') {
            this.flagPlan21 = true;
           


            const validators = [Validators.required, Validators.min(21)];
            this.registerForm3.get('cuotaremate').setValidators(validators);

       
            
          } else {
            this.flagPlan21 = false;
            const validators = [Validators.required];
            this.registerForm3.get('cuotaremate').setValidators(validators);

          }

          this.selectGrupo = this.grupos[0].codigo;
          this.selectGrupoDetalle= this.grupos[0].descripcion
          this.cargarContratos(this.selectGrupo);
        },
        (error) => {
          //this.apiPaso2=false;
          // alert(JSON.stringify(error.error)) ;
          this.errorRespuesta = error.error;
          //  alert(this.errorRespuesta.description) ;
        }
      );
  }


  async cargarContratos(gp: string) {

console.log("cargarContratos",gp);
this.gruposfilter= this.grupos
   
   
    

   this.selectGrupoDetalle=this.gruposfilter.filter(word => word.codigo == gp)[0].descripcion;
    this.principalService
      .getContratos(
        this.datosPersonales.codigo.toString(),
        this.selectPrograma.toString(),
        gp
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          // alert(response.status);
          this.contratos = response.body;
          //this.apiPaso2=true;
          // alert( JSON.stringify(this.datosPersonales));
          this.selectContrato = this.contratos[0].codigo;
          this.cargarContratosDetalle(this.selectContrato);
        },
        (error) => {
          //this.apiPaso2=false;
          // alert(JSON.stringify(error.error)) ;
          this.errorRespuesta = error.error;
          //  alert(this.errorRespuesta.description) ;
        }
      );
  }

  onKeydown(event) {
    //alert(this.contratosDetalle.cuotasPendientes);
    // var f=1;
    // if(this.flagPlan21)
    // {f=21}

    if (event <= this.contratosDetalle.cuotasPendientes && event>0) {
      this.vmontoCuotaRematar = (event * this.contratosDetalle.valorCuota)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      this.vmontoCuotaRematar = '0.00';
      this.vvalorCuotaRemate = '';
      //this.vvalorCuotaRemate=''
    }
  }

  vCuotasPagadas = '0';
  vmontoCuotasPagadas = '0.00';
  vvalorCertificado = '0.00';
  vmontoCuotaRematar = '0.00';
  vvalorCuotaRemate = '';
  vnumeroVancante='';

  //chkCuotas = false;
  //chkProrrateo = false;
  vvaplicationRemate = 0;

  checkValue(op: number) {
    if (op == 1) {
      this.chkProrrateo = false;
      this.vvaplicationRemate = 1;
    }
    if (op == 2) {
      this.chkCuotas = false;
      this.vvaplicationRemate = 2;
    }
  }

  resolved1(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  resolved(captchaResponse: string) {  console.log(`Resolved response token: ${captchaResponse}`);

}


  async cargarContratosDetalle(ct: string) {
    this.principalService
      .getContratosDetalle(
        this.datosPersonales.codigo.toString(),
        this.selectPrograma.toString(),
        this.selectGrupo,
        ct
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          // alert(response.status);
          this.contratosDetalle = response.body;
          this.vCuotasPagadas = this.contratosDetalle.cuotasPagadas.toString();
          this.vnumeroVancante=this.contratosDetalle.numeroCupo.toString();
          var diff = 21 - this.contratosDetalle.cuotasPagadas;
          this.vcuota21 = diff;

          this.vmontoCuotasPagadas = this.contratosDetalle.fechaAsamblea
            .toString()
            .substring(0, 10);
          this.vvalorCertificado = this.contratosDetalle.valorCertificado
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

          //this.apiPaso2=true;
          // alert( JSON.stringify(this.datosPersonales));
          // contratosDetalle.cuotasPagadas
        },
        (error) => {
          //this.apiPaso2=false;
          // alert(JSON.stringify(error.error)) ;
          this.errorRespuesta = error.error;
          //  alert(this.errorRespuesta.description) ;
        }
      );
  }

  vrespuestaSolicitud1 = '';
  vrespuestaSolicitud2 = '';

  async RegistrarSolicitud() {
   if(this.vvaplicationRemate==0){this.vvaplicationRemate=1;}
    this.principalService
      .getRegistrarSolicitud(
        this.datosPersonales.codigo.toString(),
        this.datosPersonales.tipoPersona.codigo.toString(),
        this.datosPersonales.tipoDocumento.codigo.toString(),
        this.datosPersonales.numeroDocumento.toString(),
        this.datosPersonales.email.toString(),
        this.datosPersonales.celular.toString(),
        this.selectPrograma.toString(),
        this.selectGrupo.toString(),
        this.selectContrato.toString(),
        this.contratosDetalle.codigoCertificado.toString(),
        this.cuotaremate.value,
        this.montoremate.value,
        this.vvaplicationRemate.toString(),
        //'1',//vaplicationRemate: string,
        this.autoriza.value,
        this.declaro.value
      )
      .subscribe(
        (response: any) => {
          this.envioSolicitud = response.body;
          console.log(response);
          // alert(response.status);
          // this.datosPersonales=response.body;
          //this.apiPaso2=true;
          // alert( JSON.stringify(this.datosPersonales));
          this.vrespuestaSolicitud1 = this.envioSolicitud[0].description;
          this.vrespuestaSolicitud2 = this.envioSolicitud[1].description;
        },
        (error) => {
          //this.apiPaso2=false;
          // alert(JSON.stringify(error.error)) ;
          this.errorRespuestaSolicitud = error.error;
          this.vrespuestaSolicitud1 = this.errorRespuestaSolicitud[0].description;
          this.vrespuestaSolicitud2 = this.errorRespuestaSolicitud[1].description;
          //  alert(this.errorRespuesta.description) ;
        }
      );
  }

  regForm: FormGroup;

  submitted = false;
  isStep1 = false;
  isStep2 = false;
  isStep3 = false;
  isStep4 = false;
  paso1 = 'Paso 1 Completado';
  paso2 = 'Paso 1';
  paso3 = 'Paso 1';
  paso4 = 'Paso 1';
  rolee: Boolean;

  async ValidarStep(stepper: MatStepper, paso: string) {

        this.enviado = true;

        console.log("chkcuotas",this.chkCuotas);
        console.log("chkProrrateo",this.chkProrrateo);

        if(!this.chkCuotas && !this.chkProrrateo)
        {
          this.isValidRemate = false;
          return;   
        }
        else
          this.isValidRemate = true;

        if(!this.chkAutorizo)
           return;

        
        if(!this.chkDeclaro)
           return;
   

        this.isValidRemate = true;
      
        paso = 'Paso ' + paso;

        stepper.selected.label = paso;
        stepper.selected.completed = true;
        stepper.selected.editable = false;
        stepper.next();

        // this.stepper.next();

        if (paso == 'Paso 3') {
          stepper.selectedIndex = 3;
          paso = 'Paso 4';

          stepper.selected.label = paso;
          stepper.selected.completed = true;
          stepper.selected.editable = false;
          stepper.next();
        }
      
    
    //  this.isStep1 = false;
  }

  //async  ValidarStep2(stepper: MatStepper,paso:string,_registerForm) {}
  async ValidarStep2(stepper: MatStepper, paso: string, _registerForm) {
    this.spinner.show();
    if (paso == '1') {
      // var aaaww=  await this.cargarData() ;
      //  this.cargarData('_registerForm.value.documento','registerForm.value.correo','registerForm.value.celular');
      this.principalService
        .getValidaDatosPersonales(
          _registerForm.value.documento,
          _registerForm.value.correo,
          _registerForm.value.celular
        )
        .subscribe(
          (response: any) => {
            console.log(response);
            // alert(response.status);
            this.datosPersonales = response.body;
            this.apiPaso1 = true;
            // alert( JSON.stringify(this.datosPersonales));

            //  this.cargarProgramas(this.datosPersonales.codigo);
            this.spinner.hide();
            stepper.selected.label = 'Paso ' + paso;
            stepper.selected.completed = true;
            stepper.selected.editable = false;
            stepper.next();
           
          },
          (error) => {
            this.spinner.hide();
            this.apiPaso1 = false;
            // alert(JSON.stringify(error.error)) ;
            this.errorRespuesta = error.error;
            // alert(this.errorRespuesta.description) ;

            stepper.selected.label = 'Paso ' + paso;
            stepper.selected.completed = true;
            stepper.selected.editable = true;
            stepper.next();
          }
        );
    }

    if (paso == '2') {
      //   this.ValidaCodigoSeguridad(_registerForm.value.codigosms, this.datosPersonales.codigo, this.contadosms);
      this.codigoSms = _registerForm.value.token1 + _registerForm.value.token2 + _registerForm.value.token3 + _registerForm.value.token4 + _registerForm.value.token5 + _registerForm.value.token6;
      console.log("codigoSms",this.codigoSms);
      this.contadosms = this.contadosms + 1;
      this.principalService
        .getValidaCodigoSeguridad(
         // _registerForm.value.codigosms,
          parseInt(this.codigoSms),
          this.datosPersonales.codigo,
          this.contadosms
        )
        .subscribe(
          (response: any) => {
            console.log(response);
            // alert(response.status);
            // this.datosPersonales=response.body;
            this.apiPaso2 = true;
            // alert( JSON.stringify(this.datosPersonales));
            this.spinner.hide();
            stepper.selected.label = 'Paso ' + paso;
            stepper.selected.completed = true;
            stepper.selected.editable = false;
            stepper.next();
            document.getElementById('cuotaremate').focus();
          },
          (error) => {
            this.apiPaso2 = false;
            // alert(JSON.stringify(error.error)) ;
            this.errorRespuesta = error.error;
            //  alert(this.errorRespuesta.description) ;
            this.spinner.hide();
            stepper.selected.label = 'Paso ' + paso;
            stepper.selected.completed = true;
            stepper.selected.editable = true;
            stepper.next();
          }
        );

      document.getElementById('cuotaremate').focus();
    }

    // this.stepper.next();

    if (paso == '3') {
      stepper.selectedIndex = 3;
      paso = 'Paso 4';

      stepper.selected.label = 'Paso ' + paso;
      stepper.selected.completed = true;
      stepper.selected.editable = false;
      stepper.next();
    }

    //  this.isStep1 = false;
  }

  // Paso 1
  get documento() {
    return this.registerForm.get('documento');
  }
  get correo() {
    return this.registerForm.get('correo');
  }
  get celular() {
    return this.registerForm.get('celular');
  }

  get capcha() {
    return this.registerForm.get('capcha');
  }

  //Paso 2
  get codigosms() {
    return this.registerForm2.get('codigosms');
  }

  get token1(){
    return this.registerForm2.get('token1');
  }

  get token2(){
    return this.registerForm2.get('token2');
  }

  get token3(){
    return this.registerForm2.get('token3');
  }

  get token4(){
    return this.registerForm2.get('token4');
  }

  get token5(){
    return this.registerForm2.get('token5');
  }

  get token6(){
    return this.registerForm2.get('token6');
  }

  // Paso 3
  get dniruc() {
    return this.registerForm3.get('dniruc');
  }
  get apellidos() {
    return this.registerForm3.get('apellidos');
  }
  get asociado() {
    return this.registerForm3.get('asociado');
  }

  get programa() {
    return this.registerForm3.get('programa');
  }
  get grupo() {
    return this.registerForm3.get('grupo');
  }

  get contrato() {
    return this.registerForm3.get('contrato');
  }

  get certificado() {
    return this.registerForm3.get('certificado');
  }
  get numerocuota() {
    return this.registerForm3.get('numerocuota');
  }
  get montocuota() {
    return this.registerForm3.get('montocuota');
  }
  get cuotaremate() {
    return this.registerForm3.get('cuotaremate');
  }
  get montoremate() {
    return this.registerForm3.get('montoremate');
  }
  get aplicaremate() {
    return this.registerForm3.get('aplicaremate');
  }
  get prorrateo() {
    return this.registerForm3.get('prorrateo');
  }
  get autoriza() {
    return this.registerForm3.get('autoriza');
  }
  get declaro() {
    return this.registerForm3.get('declaro');
  }

  get recaptchaReactive() {
    return this.registerForm.get('recaptchaReactive');
  }
  
  registerForm = this.formBuilder.group({
    documento: [
      '',
      {
        validators: [Validators.required, Validators.minLength(8)],
        //asyncValidators: [this.usernameUnicoService.validate.bind(this.usernameUnicoService)],
        // updateOn: 'blur'
      },
    ],
    correo: [
      '',
      {
        validators: [Validators.required, Validators.email],
      },
    ],
    celular: [
      '',
      {
        validators: [Validators.required, Validators.minLength(9)],
      },
    ],
    // capcha: [
    //   '',
    //   {
    //     validators: [Validators.required]
    //   }
    // ],

     recaptchaReactive: [
     null,
      {
        validators: [Validators.required]
     }
     ]
  });

  registerForm2 = this.formBuilder2.group({
    // codigosms: [
    //   '',
    //   {
    //     validators: [Validators.required, Validators.minLength(6)],
    //   },
    // ],
    token1: [
      '',
      {
        validators: [Validators.required],
      },
    ],
    token2: [
      '',
      {
        validators: [Validators.required],
      },
    ],
    token3: [
      '',
      {
        validators: [Validators.required],
      },
    ],
    token4: [
      '',
      {
        validators: [Validators.required],
      },
    ],
    token5: [
      '',
      {
        validators: [Validators.required],
      },
    ],
    token6: [
      '',
      {
        validators: [Validators.required],
      },
    ]
  });

  registerForm3 = this.formBuilder3.group({

    dniruc: ['', {}],
    apellidos: ['', {}],
    asociado: ['', {}],
    programa: ['', {}],
    grupo: [
      '',
      {
        validators: [Validators.required],
      },
    ],

    contrato: ['', {}],
    certificado: ['', {}],
    numerocuota: ['', {}],
    montocuota: ['', {}],
    cuotaremate: [
      '',
      {
        validators: [Validators.required],
      },
    ],
    montoremate: ['', {}],
    aplicaremate: ['', {}],
    prorrateo: ['', {}],
    autoriza: [
      '',
      {
        validators: [Validators.required],
      },
    ],
    declaro: [
      '',
      {
        validators: [Validators.required],
      },
    ],
  });

  //matcher = new MyErrorStateMatcher();


  suscripciones: any[] = [];

  agregarTelefono() {
    const telefonoFormGroup = this.formBuilder.group({
      telefono: '',
    });
  }

  contadosms = 0;

  selectPrograma = '0';
  selectGrupo = '';
  selectContrato = '';
  selectGrupoDetalle='';

  submit(_registerForm, num) {
    if (!_registerForm.valid) {
      console.log('Error');
      //this.isLoading=false;
      return;
    } else {
      console.log(JSON.stringify(_registerForm.value));
      // alert(JSON.stringify(_registerForm.value));
      if (num == 1) {
        //   this.principalService.getValidaDatosPersonales(_registerForm.value.documento,_registerForm.value.correo,_registerForm.value.celular)
        //   .subscribe((response:any) =>{
        //     console.log(response);
        //    // alert(response.status);
        //     this.datosPersonales=response.body;
        // this.apiPaso1=true;
        //      // alert( JSON.stringify(this.datosPersonales));
        //   },
        //     error =>{
        //       this.apiPaso1=false;
        //      // alert(JSON.stringify(error.error)) ;
        //       this.errorRespuesta=error.error
        //      // alert(this.errorRespuesta.description) ;
        //     });
        //   this.cargarData(_registerForm.value.documento,_registerForm.value.correo,_registerForm.value.celular);
      }
      if (num == 2) {
        this.cargarProgramas(this.datosPersonales.codigo);
        //this.cargarGrupos('AU0001');
        // this.contadosms=this.contadosms+1;
        // this.ValidaCodigoSeguridad(_registerForm.value.codigosms, this.datosPersonales.codigo, this.contadosms);
        this.LlenarFormulario3();
      }
      if (num == 3) {
        if(this.enviado && this.isValidRemate && this.chkAutorizo && this.chkDeclaro)
           this.RegistrarSolicitud();
      }
      //alert(_registerForm.value.documento);
    }
  }

  winclosed() {
    window.close();
  }

  LlenarFormulario3() {

    var vapellido
if  (this.datosPersonales.apellidoPaterno)
{
vapellido = this.datosPersonales.apellidoPaterno +
' ' +
this.datosPersonales.apellidoMaterno +
', ' +
this.datosPersonales.nombres
}
else{
  vapellido =this.datosPersonales.razonSocial

}

    this.registerForm3.patchValue({
      dniruc: this.datosPersonales.numeroDocumento,
      apellidos: vapellido,
        
      asociado: '',
      programa: '',
      grupo: '',
      certificado: '0',
      numerocuota: '0',
      montocuota: '0.00',
      cuotaremate: '',
      montoremate: '0.00',
      aplicaremate: '',
      prorrateo: '',
      autoriza: '',
      declaro: '',
    });
  }

  refrescar() {
    this.registerForm.patchValue({
      documento: '',
      correo: '',
      celular: '',
      capcha: '',
    });

    this.registerForm2.patchValue({
      codigosms: '',
      token1: '',
      token2: '',
      token3:'',
      token4:'',
      token5:'',
      token6:''
    });

    this.registerForm3.patchValue({
      dniruc: '',
      apellidos: '',
      asociado: '',
      grupo: '',
      certificado: '',
      numerocuota: '',
      montocuota: '',
      cuotaremate: '',
      montoremate: '',
      aplicaremate: '',
      prorrateo: '',
      autoriza: '',
      declaro: '',
    });
  }
}
