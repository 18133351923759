<app-principal></app-principal>  
<!-- <form [formGroup]="reactiveForm">
    <re-captcha
    siteKey="6LdSxegUAAAAALKXd6twxnKc3eJI3ijGvvq-uwMB"
    formControlName="recaptchaReactive"
    ></re-captcha>
    <button [disabled]="reactiveForm.invalid">Submit</button>
  </form> -->
  <!-- // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop -->
  <!-- xl: 1200px -->
  <!-- <div >

  </div> -->
  <!-- <div class="container" >
    <div class="row">
      <div class="col-md-2" style="background: blue;">
        One of three columns
      </div>
      <div class="col-md-8" style="background: red;">
        One of three columns
      </div>
      <div class="col-md-2" style="background: yellow;">
        One of three columns
      </div>
    </div>
  </div> -->
