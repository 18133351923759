// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  name:"DEV",
  // baseUrl:"http://172.22.1.98/autoplanremate/",
  baseUrl:"https://www.remateautoplan.com/files/",
  // apiBaseUrl:"http://172.22.1.98/autoplanassociated/api/v1/",
  apiBaseUrl:"https://www.remateautoplan.com/remateservices/auctionrequestservices/",
  //apiBaseUrl:"http://www.remateautoplan.com:8085/remateservices/auctionrequestservices/",
  //reCapchaKeyV2:"6LeE-sMUAAAAAAfd2v5GZz0Jt5JV-X5SR3gPpNLy"
  reCapchaKeyV2:"6LcH8-gUAAAAAO7rmL65UyEz97-96IQgky4gslGg"
};

/*


  private apiURLDatosPersonales = this._baseurl+'Associated/Validate?'; 
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
