import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { IDatosPersonales }  from './datos-personales'
import { mapToMapExpression } from '@angular/compiler/src/render3/util';
import { observable } from 'rxjs';
import {environment} from 'src/environments/environment'

import 'rxjs/add/operator/catch';


@Injectable({
  providedIn: 'root'
})

export class PrincipalServiceService {

  private _baseurl=environment.apiBaseUrl;
  private apiURLDatosPersonales = this._baseurl+'Associated/Validate?'; 
  private apiURLCodigoSeguridad = this._baseurl+'securitycode/validate'; 
  private apiURLProgramas= this._baseurl+'associated/'; 
  private apiURLGrupos= this._baseurl+'associated/'; 
  private apiURLContratos= this._baseurl+'associated/';
  private apiURLContratosDetalle= this._baseurl+'associated/';
  private apiURLRegistrarSolicitud= this._baseurl+'auctionrequest';


  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

 
  getValidaDatosPersonales(d:string,cr:string,c:string): Observable<any> {
   
    return this.http.get(this.apiURLDatosPersonales+'numerodocumento='+d+'&email='+cr+'&celular='+c
      
      ,{observe:'response'});
      
  }

  getValidaCodigoSeguridad(cs:number,ca:number,ni:number): Observable<any> {
   
    return this.http.post(this.apiURLCodigoSeguridad,
      {
        CodigoSeguridad: +cs,
        codigoAsociado: ca,
        numeroIntento: ni
      }
      
      ,{observe:'response'});
      
  }
  getProgramas(ca:number): Observable<any> {
   
    return this.http.get(this.apiURLProgramas+ca+'/programs'
      
      ,{observe:'response'});
      
  }



  getGrupos(ca:string,cp:string): Observable<any> {

    //http://172.22.1.98/autoplan/api/v1/associated/100/programs/AU0001/groups
   
    return this.http.get(this.apiURLGrupos+ca+'/programs/'+cp+'/groups'
      
      ,{observe:'response'});
      
  }



  getContratos(ca:string,pg:string,gp:string): Observable<any> {
   
    return this.http.get(this.apiURLContratos+ca+'/programs/'+pg+'/groups/'+gp+'/contracts'
      
      ,{observe:'response'});
      
  }
  
  getContratosDetalle(ca:string,pg:string,gp:string,ct:string): Observable<any> {
   
    return this.http.get(this.apiURLContratosDetalle+ca+'/programs/'+pg+'/groups/'+gp+'/contracts/'+ct
      
      ,{observe:'response'});
      
  }


  getRegistrarSolicitud(
    vcodigoAsociado:string,
    vtipoPersona:string,
    vtipoDocuemnto:string,
    vnumeroDocumento:string,
    vemail:string,
    vcelular:string,
    vcodigoPrograma: string,
    vcodigoGrupo: string,
    vcodigoContrato: string,
    vcodigoCertificado: string,
    vnumeroCuotasRematadas: string,
    vmontoCuotasRematadas: string,
    vaplicationRemate: string,
    vautorizacionDigital: boolean,
    vlecturaTerminosCondiciones: Boolean
    
    ): Observable<any> {
   
    return this.http.post(this.apiURLRegistrarSolicitud,
      
      {
        asociado: {
          codigo: vcodigoAsociado,
          tipoPersona: {
            codigo: vtipoPersona
          },
          tipoDocumento: {
            codigo: vtipoDocuemnto
          },
          numeroDocumento: vnumeroDocumento,
          email: vemail,
          celular: vcelular
        },
        codigoPrograma: vcodigoPrograma,
        codigoGrupo: vcodigoGrupo,
        codigoContrato: vcodigoContrato,
        codigoCertificado: vcodigoCertificado,
        numeroCuotasRematadas: vnumeroCuotasRematadas,
        montoCuotasRematadas: vmontoCuotasRematadas,
        aplicationRemate: vaplicationRemate,
        autorizacionDigital: true,
        lecturaTerminosCondiciones: true
      }








      ,{observe:'response'});



      
      
  }
  

    //  api/v1/associated/100/programs/100/groups/100/contracts
//    api/v1/associated/{codigoAsociado}/programs/{codigoPrograma}/groups/{codigoGrupo}/contracts/{codigoContrato}













  // getPersona(personaId: string): Observable<IPersona> {
  //   let params = new HttpParams().set('incluirDirecciones', "true");
  //   return this.http.get<IPersona>(this.apiURL + '/' + personaId, {params: params});
  // }

  // createPersona(persona: IPersona): Observable<IPersona> {
  //   return this.http.post<IPersona>(this.apiURL, persona);
  // }

  // updatePersona(persona: IPersona): Observable<IPersona> {
  //   return this.http.put<IPersona>(this.apiURL + "/" + persona.id.toString(), persona);
  // }

  // deletePersona(personaId: string): Observable<IPersona> {
  //   return this.http.delete<IPersona>(this.apiURL + "/" + personaId);
  // }
  
}
